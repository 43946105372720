import { ENDPOINTS, httpService, PaginatedResult, PagingOptions } from "@api";
import { useFetch } from "@hooks";
import { Department, Designation, EvaluationCriterion, KRA } from "@models";
import {
  AppraisalCycleType,
  AppraisalTemplateDesignationDTO,
  AppraisalTemplateEvaluationCriterionDTO,
  AppraisalTemplateKRADTO,
  PostAppraisalTemplateVM,
} from "@viewModels";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FiX } from "react-icons/fi";
import Swal from "sweetalert2";

const AddAppraisalTemplate = () => {
  const { register, handleSubmit, errors, control } = useForm();

  const { fields, remove, append } = useFieldArray({
    control,
    name: "evaluationCriteria",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);
  const [designations, setDesignations] = useState<Designation[]>([]);
  const [KRAPercentage, setKRAPercentage] = useState(0);
  const [ECPercentage, setECPercentage] = useState(100);

  const fetchDepartments = useFetch<PaginatedResult<Department>>(
    { endPoint: ENDPOINTS.departments, ignorePagination: true },
    new PaginatedResult<Department>()
  );

  const fetchKRAs = useFetch<PaginatedResult<KRA>>(
    {
      endPoint: ENDPOINTS.kras,
      ignorePagination: true,
    },
    new PaginatedResult<KRA>()
  );

  const fetchEvaluationCriteria = useFetch<
    PaginatedResult<EvaluationCriterion>
  >(
    {
      endPoint: ENDPOINTS.evaluationCriteria,
      ignorePagination: true,
    },
    new PaginatedResult<EvaluationCriterion>()
  );

  useEffect(() => {
    (async function () {
      var options = new PagingOptions(1, -1);
      options.filter<Designation>((f) =>
        f.eq("departmentId", selectedDepartmentId)
      );

      const res = await httpService(ENDPOINTS.designations, options).getAll();
      if (res.status === 200) {
        setDesignations(res?.data?.items);
      }
    })();
  }, [selectedDepartmentId]);

  const onSubmit = async (data: any, e: any) => {
    if (
      +data.evaluationCriteriaTotalPercentage + +data.kRAsTotalPercentage !==
      100
    ) {
      Swal.fire({
        icon: "error",
        text: "The total of KRA% and EC% must equal 100",
        showConfirmButton: false,
      });
      return;
    }

    const totalECWeights = data?.evaluationCriteria?.reduce(
      (p: any, c: any) => {
        return p + +c?.weight;
      },
      0
    );

    const selectedECIds = data?.evaluationCriteria?.map(
      (v: any) => v?.evaluationCriterionId
    ) as number[];
    if (selectedECIds?.hasDuplicates()) {
      Swal.fire({
        icon: "error",
        text: "You have selected the same evaluation criteria more than once.",
        showConfirmButton: false,
      });
      return;
    }

    if (data?.evaluationCriteria?.length > 0 && totalECWeights !== 100) {
      Swal.fire({
        icon: "error",
        text: "Total weights of the selected evaluation criteria must equal 100",
        showConfirmButton: false,
      });
      return;
    }

    setIsLoading(true);

    const designation: Partial<AppraisalTemplateDesignationDTO> = {
      departmentId: +data.departmentId,
      designationId: data?.designationId ? +data?.designationId : null,
    };

    const kras: Partial<AppraisalTemplateKRADTO>[] = data.kRAId?.map(
      (k: any) => ({ kRAId: +k } as Partial<AppraisalTemplateKRADTO>)
    );

    const ecs: Partial<AppraisalTemplateEvaluationCriterionDTO>[] =
      data?.evaluationCriteria?.map(
        (e: any) =>
          ({
            evaluationCriterionId: +e?.evaluationCriterionId,
            expectedTarget: +e?.expectedTarget,
            weight: +e?.weight,
          } as Partial<AppraisalTemplateEvaluationCriterionDTO>)
      );

    const template: PostAppraisalTemplateVM = {
      name: data.name,
      cycleType: data.cycleType,
      evaluationCriteriaTotalPercentage:
        +data.evaluationCriteriaTotalPercentage,
      kRAsTotalPercentage: +data.kRAsTotalPercentage,
      remarks: data.remarks,
      appraisalTemplateDesignations: [designation],
      appraisalTemplateKRAs: kras || null,
      appraisalTemplateEvaluationCriteria: ecs || null,
    };

    const res = await httpService(ENDPOINTS.appraisalTemplates).post(template);
    if (res.status === 201) {
      Swal.fire({
        icon: "success",
        text: "New Template has been successfully registered.",
        showConfirmButton: false,
      });
    }

    setIsLoading(false);
    e.target.reset();
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">New Appraisal Template</h3>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-2 d-flex mt-3">
          <div className="card profile-box flex-fill">
            <div className="card-body">
              <h5>Template Info</h5>
              <hr />

              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label>Template Name</label>
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter payroll group name"
                        name="name"
                        ref={register({ required: true })}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label>Cycle Type</label>
                    <div className="col-12">
                      <select
                        className="form-control select"
                        name="cycleType"
                        ref={register({ required: true })}
                      >
                        <option></option>
                        {Object.keys(AppraisalCycleType).map((d, i) => (
                          <option key={i} value={d}>
                            {d}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label>KRA %</label>
                    <div className="col-12">
                      <input
                        defaultValue={KRAPercentage}
                        type="number"
                        className="form-control"
                        placeholder="Enter payroll group name"
                        name="kRAsTotalPercentage"
                        ref={register({ required: true })}
                        onChange={(e) => setKRAPercentage(+e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label>EC %</label>
                    <div className="col-12">
                      <input
                        defaultValue={ECPercentage}
                        type="number"
                        className="form-control"
                        placeholder="Enter payroll group name"
                        name="evaluationCriteriaTotalPercentage"
                        ref={register({ required: true })}
                        onChange={(e) => setECPercentage(+e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="reason">Remarks</label>
                    <textarea
                      className="form-control mb-4"
                      name="remarks"
                      id="remarks"
                      aria-label="With textarea"
                      ref={register({ required: true })}
                    ></textarea>
                    <span className="text-danger">
                      {errors.reason && <span>This field is required</span>}
                    </span>
                  </div>
                </div>
              </div>

              <h5 className="mt-3">Departments & Designations</h5>
              <hr />

              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label>Department</label>
                    <select
                      className="form-control select"
                      name="departmentId"
                      ref={register({ required: true })}
                      onChange={(e) => setSelectedDepartmentId(+e.target.value)}
                    >
                      <option></option>
                      {fetchDepartments?.data?.items?.map(
                        (r: Department, i: any) => {
                          return (
                            <option key={i} value={r.id}>
                              {r.name}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label>Designation</label>
                    <select
                      className="form-control select"
                      name="designationId"
                      ref={register()}
                    >
                      <option></option>
                      {designations?.map((r: Designation, i: any) => {
                        return (
                          <option key={i} value={r.id}>
                            {r.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              {KRAPercentage > 0 && (
                <>
                  <h5 className="mt-3">Key Result Areas</h5>
                  <hr />

                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        {/* <label>select KRAs</label> */}
                        <select
                          className="form-control select"
                          name="kRAId"
                          ref={register({ required: true })}
                          multiple
                          style={{ height: "100px" }}
                        >
                          <option></option>
                          {fetchKRAs?.data?.items?.map((r: KRA, i: any) => {
                            return (
                              <option key={i} value={r.id}>
                                {r.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {ECPercentage > 0 && (
                <>
                  <div className="d-flex align-items-end justify-content-start mt-3">
                    <h5 className="">Evaluation Criteria</h5>
                    <button
                      onClick={() =>
                        append({
                          criterion: "",
                          score: 0,
                        })
                      }
                      className="mx-3 btn btn-sm btn-warning"
                    >
                      + Add Criteria
                    </button>
                  </div>

                  <hr />

                  <div className="row">
                    {fields.map((field, idx) => {
                      return (
                        <div
                          className="d-flex align-items-center"
                          key={field.id}
                        >
                          <div className="col-5">
                            <div className="form-group">
                              <label
                                htmlFor={`evaluationCriteria.${idx}.criterion`}
                              >
                                Criterion*
                              </label>
                              <select
                                className="form-control select"
                                name={`evaluationCriteria.${idx}.evaluationCriterionId`}
                                ref={register({ required: true })}
                                style={{ height: "36px" }}
                              >
                                <option></option>
                                {fetchEvaluationCriteria?.data?.items?.map(
                                  (r: EvaluationCriterion, i: any) => {
                                    return (
                                      <option key={i} value={r.id}>
                                        {r.name} - {r.unit}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label
                                htmlFor={`evaluationCriteria.${idx}.expectedTarget`}
                              >
                                Expected Target*
                              </label>
                              <input
                                style={{ height: "35px" }}
                                type="string"
                                className="form-control"
                                id={`evaluationCriteria.${idx}.expectedTarget`}
                                name={`evaluationCriteria.${idx}.expectedTarget`}
                                ref={register({ required: true })}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label
                                htmlFor={`evaluationCriteria.${idx}.weight`}
                              >
                                Weight*
                              </label>
                              <input
                                style={{ height: "35px" }}
                                type="number"
                                className="form-control"
                                id={`evaluationCriteria.${idx}.weight`}
                                name={`evaluationCriteria.${idx}.weight`}
                                ref={register({ required: true })}
                              />
                            </div>
                          </div>
                          <div className="col-auto mt-3">
                            <FiX
                              size={25}
                              color="red"
                              onClick={() => remove(idx)}
                              style={{
                                cursor: "pointer",
                                fontWeight: "bolder",
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              <div className="d-flex justify-content-end mt-5">
                <input
                  type="submit"
                  name="time"
                  className="btn btn-dark"
                  disabled={isLoading}
                  value={isLoading ? "Please wait..." : "Create Template"}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddAppraisalTemplate;
