import { ENDPOINTS, httpService, Operation, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { Department, Designation } from "@models";
import {
  Action,
  ComplexHeader,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AddDesignation } from "@components";
import { FiPlus } from "react-icons/fi";

const ListDesignations = () => {
  const [queryStrings] = useSearchParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchDesignations = useFetch<PaginatedResult<Designation>>(
    {
      endPoint: ENDPOINTS.designations,
      queryStrings: queryStrings,
      reload: reload,
    },
    new PaginatedResult<Designation>()
  );

  const fetchDepartments = useFetch<PaginatedResult<Department>>(
    {
      endPoint: ENDPOINTS.departments,
      ignorePagination:true,
      reload: reload,
    },
    new PaginatedResult<Department>()
  );

  useEffect(() => {}, [fetchDesignations?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "department?.name", title: "Department" },
    { key: "name", title: "Designation" },
    { key: "createdBy", title: "By" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const filters: Filterable<Designation>[] = [
    { key: "name", title: "name", format: "text", operation: Operation.like },
    {
      key: "departmentId",
      title: "Department",
      format: "select",
      data: fetchDepartments?.data?.items?.map((d) => ({
        id: d.id,
        name: d.name,
      })),
    },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (d: Designation) => {
        onAddUserClickHandler(d);
      },
      title: "Edit",
      color: "warning",
    },
    // {
    //   key: "",
    //   actionType: "badge",
    //   click: async (d: Designation) => {
    //     return;
    //     await httpService(ENDPIONTS.designations).delete(d.id);
    //     setReload((prev) => (prev === undefined ? true : !prev));
    //   },
    //   title: "Delete",
    //   color: "danger",
    // },
  ];

  const onAddUserClickHandler = (d?: Designation) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <AddDesignation
          selectedDesignation={d}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Designations</h3>
        </div>
        <div className="col-auto float-end ms-auto">
          <button
            className="btn btn-sm btn-primary rounded-circle m-1"
            onClick={() => onAddUserClickHandler()}
          >
            <FiPlus />
          </button>
          <TableVerticalConfigs filters={filters} />
        </div>
      </div>
      <div className="col-sm-12 col-md-8 offset-md-2 mt-3">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={fetchDesignations?.data ?? []}
          headers={headers}
          isFetchingPage={fetchDesignations.isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>
    </div>
  );
};

export default ListDesignations;
