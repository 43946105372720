import { ENDPOINTS, httpService } from "@api";
import React, { useState } from "react";
import Swal from "sweetalert2";

interface Props {
  userId: number;
  apps: string[];
  callback?: () => void;
}

const UserApps = ({ userId, apps, callback }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedApps, setSelectedApps] = useState<string[]>([]);

  const onSubmit = async () => {
    setIsLoading(true);

    const obj = {
      userId: userId,
      apps: selectedApps,
    };

    const res = await httpService(ENDPOINTS.allowedApps).update(userId, obj);
    if (res.status === 201) {
      Swal.fire({
        icon: "success",
        text: "User's Accessible Applications has been successfully updated.",
        showConfirmButton: false,
      });
    }

    callback?.();
    setIsLoading(false);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const options = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setSelectedApps(options);
  };

  return (
    <>
      <h3>User's Accessible Applications</h3>
      <hr />

      <div className="row">
        <div className="col-sm-12 col-md">
          <span>Currently accessible Apps</span>
        </div>
        <div className="col-sm-12 col-md">
          {apps.length === 0 ? "None" : apps.map((a) => <strong>{a}, </strong>)}
        </div>
      </div>

      <div className="container mt-5">
        <div className="col-12">
          <div className="form-group">
            <label>Select apps to add</label>
            <select
              className="form-control select"
              multiple
              onChange={handleSelectChange}
            >
              <option value="HR">HR</option>
              <option value="EmployeePortal">EmployeePortal</option>
            </select>
          </div>
        </div>
        <div className="offset-10 col-2 ">
          <input
            onClick={onSubmit}
            type="button"
            className="btn btn-primary"
            disabled={isLoading}
            value={isLoading ? "Please wait..." : "Update"}
          />
        </div>
      </div>
    </>
  );
};

export default UserApps;
