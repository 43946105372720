import { ENDPOINTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { AllowanceType, OverTime, OverTimeType } from "@models";
import { DateHelpers } from "@shared";
import { useEmployeeStore, usePayrollStore } from "@stores";
import { FetchOverTimesDTO } from "@viewModels";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

class NewOverTime {
  id?: number;
  employeeId: number;
  overTimeTypeId: number;
  allowanceTypeId: number;
  date: string;
  hours: number;
  description: string;
}

interface Props {
  employeeId: number;
  overtime?: FetchOverTimesDTO;
  callback?: () => void;
}

const AddOverTime: React.FC<Props> = ({ callback, overtime, employeeId }) => {
  const clearSotrage = usePayrollStore((s) => s.clearStorage);
  const calculateSalary = useEmployeeStore((s) => s.calculateSalary);

  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [allowanceType, setAllowanceType] = useState<AllowanceType | null>(
    null
  );

  const fetchOverTimeType = useFetch<PaginatedResult<OverTimeType>>(
    { endPoint: ENDPOINTS.overTimeTypes },
    new PaginatedResult<OverTimeType>()
  );
  const fetchAllowanceTypes = useFetch<PaginatedResult<AllowanceType>>(
    { endPoint: ENDPOINTS.allowanceTypes },
    new PaginatedResult<AllowanceType>()
  );

  useEffect(() => {
    if (!fetchAllowanceTypes?.data?.items?.isEmpty()) {
      setAllowanceType(
        fetchAllowanceTypes?.data?.items?.filter(
          (a) => a.name === "Overtime"
        )[0]
      );
    }
  }, [fetchAllowanceTypes.isFetching, fetchOverTimeType.isFetching]);

  const onSubmit = async (data: any, e: any) => {
    if (!employeeId) return;
    if (!allowanceType) return;

    setIsLoading(true);

    let dType: NewOverTime = {
      employeeId: employeeId,
      overTimeTypeId: data.overTimeTypeId,
      allowanceTypeId: allowanceType?.id,
      date: data.date,
      hours: data.hours,
      description: data.description,
    };

    if (overtime?.id) {
      dType.id = overtime.id;
      const res = await httpService(ENDPOINTS.overTimes).update(
        overtime.id,
        dType
      );
      if (res.status === 204) {
        clearSotrage();
      }
    } else {
      await httpService(ENDPOINTS.overTimes).post(dType);
    }

    calculateSalary(employeeId);

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>{overtime?.id ? "Edit" : "Add"} Overtime</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label>Allowance Type</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={allowanceType?.name}
                  className="form-control"
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label>Overtime Type</label>
              <select
                className="form-control select"
                name="overTimeTypeId"
                ref={register({ required: true })}
              >
                <option></option>
                {fetchOverTimeType?.data?.items?.map(
                  (r: OverTimeType, i: any) => {
                    return (
                      <option
                        key={i}
                        value={r.id}
                        selected={r.id === overtime?.overTimeTypeId}
                      >
                        {r.name} - {r.rate}
                      </option>
                    );
                  }
                )}
              </select>
              <span className="text-danger">
                {errors.overTimeTypeId && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label>Date</label>
              <div className="col-12">
                <input
                  defaultValue={DateHelpers.setInputDate(overtime?.date)}
                  type="date"
                  className="form-control"
                  name="date"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.date && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label>Working Hours</label>
              <div className="col-12">
                <input
                  defaultValue={overtime?.hours}
                  type="number"
                  className="form-control"
                  name="hours"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.hours && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="description">Remarks</label>
              <textarea
                defaultValue={overtime?.description ?? ""}
                className="form-control mb-4"
                name="description"
                id="description"
                aria-label="With textarea"
                ref={register({ required: true })}
              ></textarea>
              <span className="text-danger">
                {errors.description && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        {allowanceType ? (
          <input
            type="submit"
            name="time"
            className="btn btn-primary"
            disabled={isLoading}
            value={
              isLoading
                ? "Please wait..."
                : fetchAllowanceTypes.isFetching
                ? "Loading..."
                : "Register"
            }
          />
        ) : (
          <small className="text-danger">
            To use the overtime functionality, you should add an allowance type
            with the name "Overtime"
          </small>
        )}
      </form>
    </>
  );
};

export default AddOverTime;
