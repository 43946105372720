import { ENDPOINTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { CompanyProfile, DocumentType, Employee } from "@models";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const AddProfile = () => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const fetchProfile = useFetch<CompanyProfile>(
    { endPoint: ENDPOINTS.companyProfiles },
    new CompanyProfile()
  );

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append("CompanyName", data.companyName);

    const res = await httpService(ENDPOINTS.companyProfiles).post(formData);
    if (res.status === 201) {
      e.target.reset();
      localStorage.removeItem("fileData");
      localStorage.removeItem("companyName");

      window.location.reload();
    }
    setIsLoading(false);
  };

  useEffect(() => {}, [fetchProfile.isFetching]);

  return (
    <div className="container">
      <div className="card profile-box flex-fill">
        <div className="card-body">
          <h4>Company Profile</h4>
          <hr />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label>Company Name</label>
                  <div className="col-12">
                    <input
                      defaultValue={fetchProfile?.data?.name}
                      type="text"
                      className="form-control"
                      placeholder="Enter location"
                      name="companyName"
                      ref={register({ required: true })}
                      style={{height:'36px'}}
                    />
                    <span className="text-danger">
                      {errors.companyName && (
                        <span>This field is required</span>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="file">Select Logo</label>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className="form-control"
                    ref={register({ required: true })}
                  />
                  <span className="text-danger">
                    {errors.file && <span>This field is required</span>}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <input
                type="submit"
                name="time"
                className="btn btn-primary"
                disabled={isLoading}
                value={isLoading ? "Please wait..." : "Submit"}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProfile;
