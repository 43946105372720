import { ENDPOINTS, httpService } from '@api';
import { Employee } from '@models';
import { DateHelpers } from '@shared';
import { EmployeePersonalInfo } from '@viewModels';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';


interface Props {
    callback?: () => void
    employee?: Employee;
}

const maritalStatus = ["Single", "Married", "Divorced"]

const EditPersonalInfo: React.FC<Props> = ({ callback, employee }) => {
    const { register, handleSubmit, errors } = useForm();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        return () => {
            employee = undefined;
        }
    }, [employee]);

    const onSubmit = async (data: any, e: any) => {
        if (!employee?.id) return;

        setIsLoading(true);

        let emp: EmployeePersonalInfo = {
            id: employee?.id,
            passportNo: data.passportNo,
            passportExpiry: data.passportExpiry,
            nationality: data.nationality,
            religion: data.religion,
            maritalStatus: data.maritalStatus,
            children: data.children,
        }

        await httpService(ENDPOINTS.employeePersonalInfo).update(employee.id, emp);

        callback?.();
        setIsLoading(false);
        e.target.reset();
    }

    return (
        <>
            <h4>    Edit Employee Personal Info </h4>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                            <label>Passport No</label>
                            <div className="col-12">
                                <input type="text" defaultValue={employee?.passportNo ?? ''} className="form-control" name='passportNo' ref={register()} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                            <label>Passport Expiry</label>
                            <div className="col-12">
                                <input type="date" defaultValue={DateHelpers.setInputDate(employee?.passportExpiry)} className="form-control" name='passportExpiry' ref={register()} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                            <label>Nationality</label>
                            <div className="col-12">
                                <input type="text" defaultValue={employee?.nationality ?? ''} className="form-control" name='nationality' ref={register()} />
                            </div>
                        </div>
                    </div>
                
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                            <label>Religion</label>
                            <div className="col-12">
                                <input type="text" defaultValue={employee?.religion ?? ''} className="form-control" name='religion' ref={register()} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                            <label>Marital Status</label>
                            <select className="form-control select" name='maritalStatus' ref={register()}>
                                <option></option>
                                {
                                    maritalStatus.map((m, idx) => {
                                        return (
                                            <option selected={employee?.maritalStatus === m} key={idx} value={m}>{m}</option>

                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="form-group">
                            <label>No. of children</label>
                            <div className="col-12">
                                <input type="number" defaultValue={employee?.children ?? ''} className="form-control" name='children' ref={register()} />
                            </div>
                        </div>
                    </div>
                </div>

                <input type="submit" name="time" className="btn btn-primary" disabled={isLoading} value={isLoading ? "Please wait..." : "Register"} />
            </form>
        </>
    )
}

export default EditPersonalInfo