import { ENDPOINTS, PaginatedResult } from "@api";
import { EmployeeLookUp, PaperHeader } from "@components";
import { useFetch } from "@hooks";
import { Employee, LeaveType } from "@models";
import { ComplexHeader, ExportData, Table, TableVerticalConfigs } from "@shared";
import { LeaveBalanceReport } from "@viewModels";
import { BranchSelection } from "@widgets";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const LeaveBalancesReport = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState<number>();
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [selectedBranchId, setSelectedBranchId] = useState<number>();

  const fetchData = useFetch<PaginatedResult<LeaveBalanceReport>>(
    {
      endPoint: ENDPOINTS.leaveBalancesReport,
      queryStrings: queryStrings,
    },
    new PaginatedResult<LeaveBalanceReport>()
  );

  const fetchLeaveTypes = useFetch<PaginatedResult<LeaveType>>(
    { endPoint: ENDPOINTS.leaveTypes },
    new PaginatedResult<LeaveType>()
  );

  useEffect(() => {
    if (Object.keys(Object.fromEntries(queryStrings.entries())).length === 0) {
      setSelectedLeaveTypeId(undefined);
      setSelectedBranchId(undefined);
      setSelectedEmployee(undefined);
    }
  }, [queryStrings, fetchLeaveTypes.isFetching]);

  useEffect(() => {
    if (selectedLeaveTypeId && selectedBranchId) {
      queryStrings.set("branchId", selectedBranchId?.toString());
      queryStrings.set("leaveTypeId", selectedLeaveTypeId?.toString());

      navigate({ search: `?${queryStrings.toString()}` });
    }

    // if (selectedLeaveTypeId) {
    //   queryStrings.set("leaveTypeId", selectedLeaveTypeId?.toString());
    //   navigate({ search: `?${queryStrings.toString()}` });
    // }
    if (selectedEmployee) {
      queryStrings.set("employeeId", selectedEmployee?.id?.toString());
      navigate({ search: `?${queryStrings.toString()}` });
    }
    // if (selectedBranchId) {
    //   queryStrings.set("branchId", selectedBranchId?.toString());
    //   navigate({ search: `?${queryStrings.toString()}` });
    // }
  }, [selectedLeaveTypeId, selectedEmployee, selectedBranchId]);

  const headers: ComplexHeader[] = [
    { key: "employeeName", title: "Name" },
    { key: "leaveType", title: "leave Type" },
    { key: "leaveBalance", title: "leave Balance" },
  ];

  const onExport: ExportData = {
    data: () => Promise.resolve(fetchData?.data?.items),
    fileName: `Annual Leave Balances Report - ${new Date().toLocaleDateString()}`,
  };

  const onPrint = (element: HTMLElement) => {
    var subElement = element.querySelectorAll(":scope > div")[0];
    subElement.classList.remove("d-none");

    var subElement02 = element.querySelectorAll(":scope > div")[1];
    subElement02.classList.add("d-none");

    let table = element.getElementsByTagName("table")[0];
    table.classList.remove("table-striped");
  };

  const onLookUpClickHandler = () => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 800,
      html: <EmployeeLookUp callback={(e: Employee) => setSelectedEmployee(e)} />,
    });
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="row d-flex justify-content-between mb-1">
          <div className="col-sm-12 col-lg-4">
            <h3 className="page-title">Leave Balances Report</h3>
          </div>
          <div className="col-sm-12 col-lg">
            <div className="row gap-2">
              <BranchSelection
                className="col-sm-12"
                returnValue={(val: number) => setSelectedBranchId(val)}
                isSelectedOption={selectedBranchId}
              />
              <select
                className="form-control select col-sm-12 col-md"
                name="leaveType"
                onChange={(e) => setSelectedLeaveTypeId(+e.target.value)}
              >
                <option></option>
                {fetchLeaveTypes?.data?.items?.map((l, idx) => (
                  <option key={idx} value={l.id} selected={l.id === selectedLeaveTypeId}>
                    {l.name}
                  </option>
                ))}
              </select>
              <input value={selectedEmployee?.name ?? ""} type="text" className="form-control col-sm-12 col-md" disabled />
              <input
                type="button"
                className="btn btn-sm btn-dark col-sm-12 col-md-2"
                value={"Look Up"}
                onClick={onLookUpClickHandler}
              />
              <input
                type="button"
                className="btn btn-sm btn-danger col-sm-12 col-md-1"
                value={"Reset"}
                onClick={() => {
                  navigate({ search: `` });
                  setSelectedEmployee(undefined);
                  setSelectedLeaveTypeId(undefined);
                  setSelectedBranchId(undefined);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-12 col-md-8 offset-md-2 mt-3" id="reportcontents">
        <PaperHeader title="Leave Balances Report" />
        <div className="row">
          <div className="col-auto float-end ms-auto">
            <TableVerticalConfigs
              filters={[]}
              exportAsXSLS={onExport}
              onPrint={{
                ref: "reportcontents",
                prePrint: onPrint,
              }}
            />
          </div>
        </div>
        <Table
          class="table table-striped table-hover mb-0"
          data={fetchData?.data}
          headers={headers}
          isFetchingPage={fetchData.isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>
    </div>
  );
};

export default LeaveBalancesReport;
