import { ENDPOINTS, httpService } from "@api";
import { useFetch } from "@hooks";
import { JobApplication } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import { JobApplicationStatus } from "@viewModels";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

class attachments {
  name: string;
  path: string;
}

interface Props {
  applicantId: number;
  callback?: () => void;
}

const ApplicantDetails = ({ applicantId, callback }: Props) => {
  const MySwal = withReactContent(Swal);

  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = useState<attachments[]>([]);
  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchData = useFetch<JobApplication>(
    {
      endPoint: ENDPOINTS.applications,
      id: applicantId,
      reload: reload,
    },
    new JobApplication()
  );

  useEffect(() => {
    if (fetchData?.data) {
      let rendering = fetchData?.data?.attachmentPaths?.map(
        (item, idx) =>
          ({ name: `attachment ${idx}`, path: item } as attachments)
      );

      setAttachments(rendering);
    }
  }, [applicantId, fetchData?.isFetching]);

  const headers: ComplexHeader[] = [{ key: "name", title: "Doc. Type" }];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (d: attachments) => {
        window.open(d.path);
      },
      title: "View",
      color: "primary",
    },
  ];

  const changeHiringStatus = async (status: JobApplicationStatus) => {
    setIsLoading(true);

    const obj = {
      id: applicantId,
      status,
    };

    const res = await httpService(ENDPOINTS.applications).update(
      applicantId,
      obj
    );
    if (res.status === 204) {
      Swal.fire({
        icon: "success",
        text: "Application status has been successfully updated.",
        showConfirmButton: false,
      });
    }

    callback?.();
    setIsLoading(false);
  };

  return (
    <>
      <h3>Applicant details</h3>
      <hr />

      <div className="row mb-5 mt-3">
        <div className="d-flex justify-content-between">
          <span>
            Full Name: <strong>{fetchData.data.fullName}</strong>{" "}
          </span>
          <span>
            Email: <strong>{fetchData.data.email}</strong>{" "}
          </span>
          <span>
            Phone: <strong>{fetchData.data.phone}</strong>{" "}
          </span>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <span>
            Date Of Birth: <strong>{fetchData.data.dateOfBirth}</strong>{" "}
          </span>
          <span>
            Country Of Residence:{" "}
            <strong>{fetchData.data.countryOfResidence}</strong>{" "}
          </span>
          <span>
            Nationality: <strong>{fetchData.data.nationality}</strong>{" "}
          </span>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <span>
            City: <strong>{fetchData.data.city}</strong>{" "}
          </span>
          <span>
            Address: <strong>{fetchData.data.address}</strong>{" "}
          </span>
        </div>
        <div className="d-flex justify-content-start mt-3">
          <span>
            Remarks: <strong>{fetchData.data.remarks}</strong>{" "}
          </span>
        </div>
      </div>

      <div className="col-sm-12 col-lg-10 offset-lg-1">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={attachments ?? []}
          headers={headers}
          isFetchingPage={fetchData.isFetching}
          showCounter
          //   onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>

      <div className="d-flex justify-content-end mt-3">
        {fetchData?.data?.status === JobApplicationStatus.Pending && (
          <>
            <button
              className="btn btn-success m-1"
              disabled={isLoading}
              onClick={() =>
                changeHiringStatus(JobApplicationStatus.Short_Listed)
              }
            >
              {isLoading ? "Processing..." : "Shortlist Candidate"}
            </button>
            <button
              className="btn btn-danger m-1"
              disabled={isLoading}
              onClick={() => changeHiringStatus(JobApplicationStatus.Rejected)}
            >
              {isLoading ? "Processing..." : "Reject Candidate"}
            </button>
          </>
        )}
        {fetchData?.data?.status !== JobApplicationStatus.Pending && (
          <>
            <button
              className="btn btn-danger m-1"
              disabled={isLoading}
              onClick={() => changeHiringStatus(JobApplicationStatus.Pending)}
            >
              {isLoading ? "Processing..." : "Roll Back"}
            </button>
          </>
        )}
        {fetchData?.data?.status === JobApplicationStatus.Short_Listed && (
          <>
            <button
              className="btn btn-dark m-1"
              disabled={isLoading}
              onClick={() => changeHiringStatus(JobApplicationStatus.Hired)}
            >
              {isLoading ? "Processing..." : "Hire Candidate"}
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default ApplicantDetails;
