import { ENDPOINTS } from "@api";
import { PaperHeader } from "@components";
import { useFetch } from "@hooks";
import { ComplexHeader, Table, TableVerticalConfigs } from "@shared";
import { BranchSelection } from "@widgets";
import { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const YearlyAppraisalTemplateUtilization = () => {
  const MySwal = withReactContent(Swal);
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [headers, setheaders] = useState<ComplexHeader[]>([]);
  const [year, setYear] = useState(
    queryStrings.get("year") ?? new Date().getFullYear().toString()
  );

  const fetchData = useFetch<{}[]>(
    {
      endPoint: ENDPOINTS.yearlyAppraisalTemplateUtilization,
      queryStrings: queryStrings,
    },
    []
  );

  useEffect(() => {
    if (fetchData?.data?.length > 0) {
      var x: ComplexHeader[] = [];

      Object.keys(fetchData?.data[0]).forEach((d) => {
        x.push({ key: d, title: d.replaceAll("_", " ") });
      });

      setheaders(x);
    }
  }, [queryStrings, fetchData.isFetching]);

  const handleSearch = async () => {
    setIsLoading(true);

    queryStrings.set("year", year);

    navigate({ search: `?${queryStrings.toString()}` });

    setIsLoading(false);
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="d-flex justify-content-between mb-1">
          <div className="col-sm-12 col-lg-4">
            <h3 className="page-title">
              Yearly Appraisal Template Utilization
            </h3>
          </div>
        </div>
      </div>

      <div className="row mt-3" id="reportcontents">
        <PaperHeader title="Leave Balances Report" />
        <div className="row d-flex justify-content-start align-items-center mb-3">
          <div className="col-sm-12 col-md-3">
            <div className="form-group mb-0 row">
              <label className="col-form-label col-auto">Year</label>
              <div className="col">
                <input
                  type="number"
                  min="2022"
                  max="2099"
                  step="1"
                  defaultValue={year}
                  className="form-control form-control-sm row"
                  onChange={(e) => setYear(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col ">
            <button
              disabled={isLoading}
              className="btn btn-sm btn-outline-info rounded-circle m-1 float-right"
              onClick={handleSearch}
            >
              <RiSearchLine />
            </button>
            {headers.length > 0 && (
              <TableVerticalConfigs
              // exportAsXSLS={onExport}
              // onPrint={{
              //   ref: "reportcontents",
              //   prePrint: onPrint,
              // }}
              />
            )}
          </div>
        </div>
        <div className="row">
          {headers.length > 0 && (
            <Table
              class="table table-striped table-hover mb-0"
              data={fetchData?.data ?? []}
              headers={headers}
              //   actions={actions}
              isFetchingPage={fetchData?.isFetching}
              showCounter
              // onPageChange={handlePageChange}
              paginationClass="row mt-3"
              // showTotals
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default YearlyAppraisalTemplateUtilization;
