import { ENDPOINTS, PaginatedResult } from "@api";
import { AddAllowanceType, AddShift } from "@components";
import { useFetch } from "@hooks";
import { AllowanceType, Shift } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ListShifts = () => {
  const MySwal = withReactContent(Swal);

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchShifts = useFetch<PaginatedResult<Shift>>(
    { endPoint: ENDPOINTS.shifts, reload: reload },
    new PaginatedResult<Shift>()
  );

  useEffect(() => {}, [fetchShifts?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "shiftName", title: "Name" },
    { key: "startTime", title: "start Time" },
    { key: "endTime", title: "end Time" },
    { key: "workingHours", title: "working Hours" },
    { key: "breakTimeHours", title: "break Time Hours" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (a: Shift) => {
        onButtonClick(null, a);
      },
      title: "Edit",
      color: "dark",
    },
  ];

  const onButtonClick = (e: any, s?: Shift) => {
    e !== null && e.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 700,
      html: (
        <AddShift
          shift={s}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-6 d-flex">
      <div className="card profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            Shifts
            <a
              href="!#"
              onClick={(e) => onButtonClick(e)}
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#personal_info_modal"
            >
              <i className="fa fa-plus" />
            </a>
          </h3>
          <Table
            actions={actions}
            class="table table-striped table-hover mb-0"
            data={fetchShifts?.data ?? []}
            headers={headers}
            isFetchingPage={fetchShifts.isFetching}
            showCounter
            // onPageChange={handlePageChange}
            paginationClass="row mt-3"
          />
        </div>
      </div>
    </div>
  );
};

export default ListShifts;
