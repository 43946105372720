import { ENDPOINTS, PaginatedResult } from "@api";
import { AddIncomeSource } from "@components";
import { useFetch } from "@hooks";
import { IncomeSource } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ListIncomeSources = () => {
  const MySwal = withReactContent(Swal);

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchIncomeSources = useFetch<PaginatedResult<IncomeSource>>(
    { endPoint: ENDPOINTS.incomeSources, reload: reload },
    new PaginatedResult<IncomeSource>()
  );

  useEffect(() => {}, [fetchIncomeSources?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "name", title: "Name" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (a: IncomeSource) => {
        onButtonClick(null, a);
      },
      title: "Edit",
      color: "dark",
    },
  ];

  const onButtonClick = (e: any, a?: IncomeSource) => {
    e !== null && e.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <AddIncomeSource incomeSource={a} callback={() => setReload((prev) => (prev === undefined ? true : !prev))} />
      ),
    });
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-5 d-flex">
      <div className="card profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            Income Sources
            <a
              href="!#"
              onClick={(e) => onButtonClick(e)}
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#personal_info_modal"
            >
              <i className="fa fa-plus" />
            </a>
          </h3>
          <Table
            actions={actions}
            class="table table-striped table-hover mb-0"
            data={fetchIncomeSources?.data ?? []}
            headers={headers}
            isFetchingPage={fetchIncomeSources.isFetching}
            showCounter
            // onPageChange={handlePageChange}
            paginationClass="row mt-3"
          />
        </div>
      </div>
    </div>
  );
};

export default ListIncomeSources;
