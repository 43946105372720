import { ENDPOINTS, Operation, PaginatedResult } from "@api";
import { AddEvaluationCriteria } from "@components";
import { useFetch } from "@hooks";
import { EvaluationCriterion } from "@models";
import {
  Action,
  ComplexHeader,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ListEvaluationCriteria = () => {
  const [queryStrings] = useSearchParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchEvaluationCriteria = useFetch<
    PaginatedResult<EvaluationCriterion>
  >(
    {
      endPoint: ENDPOINTS.evaluationCriteria,
      queryStrings: queryStrings,
      reload: reload,
    },
    new PaginatedResult<EvaluationCriterion>()
  );

  useEffect(() => {}, [fetchEvaluationCriteria?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "name", title: "Name" },
    { key: "unit", title: "unit" },
    { key: "symbol", title: "symbol" },
    // { key: "createdBy", title: "By" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const filters: Filterable<EvaluationCriterion>[] = [
    { key: "name", title: "name", format: "text", operation: Operation.like },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (d: EvaluationCriterion) => {
        onAddUserClickHandler(d);
      },
      title: "Edit",
      color: "warning",
    },
  ];

  const onAddUserClickHandler = (d?: EvaluationCriterion) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <AddEvaluationCriteria
          selectedEvaluationCriterion={d}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Evaluation Criteria</h3>
        </div>
        <div className="col-auto float-end ms-auto">
          <button
            className="btn btn-sm btn-primary rounded-circle m-1"
            onClick={() => onAddUserClickHandler()}
          >
            <FiPlus />
          </button>
          <TableVerticalConfigs filters={filters} />
        </div>
      </div>
      <div className="col-sm-12 col-md-8 offset-md-2 mt-3">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={fetchEvaluationCriteria?.data ?? []}
          headers={headers}
          isFetchingPage={fetchEvaluationCriteria.isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>
    </div>
  );
};

export default ListEvaluationCriteria;
